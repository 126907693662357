import { AnimationOnScroll } from 'react-animation-on-scroll';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useTranslation } from 'react-i18next';


const BannerHibrido = () => {
    const [t, i18n] = useTranslation("global")
    const country = process.env.REACT_APP_COUNTRY;


  return (
    <div>

    <AnimationOnScroll animateIn="animate__fadeIn mb-5">
        <LazyLoadImage src={process.env.PUBLIC_URL + t("banners.bannerHuawei")} alt="Imagen marcas" className='imagenesBody' ></LazyLoadImage>
    </AnimationOnScroll>

    {country === 'CR' ? (
      <AnimationOnScroll animateIn="animate__fadeIn mb-5">
        <LazyLoadImage src={process.env.PUBLIC_URL + "banner/panduitCR.png" } alt="banner CR" className='imagenesBody' ></LazyLoadImage>
      </AnimationOnScroll>
    ) : (
      <AnimationOnScroll animateIn="animate__fadeIn mb-5">
        <LazyLoadImage src={process.env.PUBLIC_URL + "banner/bannerHibrido.jpg" } alt="imagen body" className='imagenesBody' ></LazyLoadImage>
      </AnimationOnScroll>
    )}
      
    </div>
  )
}
export default BannerHibrido;
